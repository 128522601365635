import React from 'react'
import castle from '../images/terminals/castles-s1-f2.png'
import verifone from '../images/terminals/verifone-v210.png'

import { Legal } from '../layouts/Legal'

export default () => {

  const products = [
    {
      id: 'castles-s1-f2',
      name: 'Castles S1F2',
      href: 'castles-s1-f2',
      imageSrc: castle,
      imageAlt: "Front of men's Basic Tee in black.",
      inBox: [
        'Terminal device S1F2 EU',
        'Receipt Roll',
        'GB Power Adapter USB',
        'Includes a SIM card and battery',
      ],
      price: '£300 per year',
    },
    {
      id: 'verifone-v210',
      name: 'Verifone V210',
      href: 'verifone-v210',
      imageSrc: verifone,
      imageAlt: "Front of men's Basic Tee in black.",
      inBox: [
        'Terminal device Verifone V210',
        'Receipt Roll',
        'Power Supply GB - V400m',
        'Includes a SIM card, GB Power Supply and battery',
      ],
      price: '£360 per year',
    },
  ]

  return <Legal title="Payment Terminals">
    <div className="relative overflow-hidden bg-white">

      <section
        aria-labelledby="sale-heading"
        className="relative mx-auto flex max-w-7xl flex-col items-center px-4 text-center sm:px-6 lg:px-8"
      >
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <p className="mt-4 max-w-xl text-md text-gray-600">
            We offer a range of Payment Terminals. All devices include a terminal replacement service.
          </p>

          <p className="mt-4 max-w-xl text-md text-gray-600">
            Get in touch with your account manager to find out more information.
          </p>
        </div>
      </section>

    </div>

    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sr-only">Available Terminals</h2>

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-120 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-120">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <h3 className="mt-4 text-md text-gray-700">
                {product.name}
              </h3>
              <div className="prose prose-sm mt-4 text-gray-500">
                <ul role="list">
                  {product.inBox.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>

              <p className="mt-4 text-md font-medium text-gray-900">{product.price}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Legal>
}
